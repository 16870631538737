import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import { Select } from "../components/Core";
import axios from "axios";
import network from '../../constants/Network';
import GlobalContext from "../context/GlobalContext";
import { fontWeight } from "styled-system";
import { Modal } from "react-bootstrap";

const DashboardAppliedJobs = () => {
  const [jobs, setJobs] = useState([]);
  const gContext = useContext(GlobalContext);
  const isMountedRef = useRef(false);
  const { user } = useContext(GlobalContext);
  const [filter, setFilter] = useState('');
  const [allCategories, setAllCategories] = useState([]);
  const [totalJobCount, setTotalJobCount] = useState(0);
  const [sortByDate, setSortByDate] = useState('descending');
  const [modalCoverLetterVisible, setModalCoverLetterVisible] = useState(false);
  const [coverLetter, setCoverLetter] = useState('');

  const imageBaseUrl = `${network.serverip}/images/`;

  const [userId, setUserId] = useState('');

  const [paginationData, setPaginationData] = useState({
    total: 0,
    current_page: 1,
    last_page: 1,
    per_page: 10,
  });

  const handleFilterJob = async (filter) => {
    fetchAppliedJobs(userId, filter);
  };

  const handleSortByDate = async () => {
    const newSortByDate = sortByDate === 'ascending' ? 'descending' : 'ascending';
    setSortByDate(newSortByDate)
  };

  const jobFilterOptions = [
    { value: '', label: 'Clear Filter' },
    ...Array.from(
      new Map(
        allCategories
          .filter(item => item.id && item.title)
          .map(item => [item.id, {
            value: item.id,
            label: item.title
          }])
      ).values()
    )
  ];

  const handleModalClose = () => {
    setModalCoverLetterVisible(false);
    setCoverLetter('');
  };

  const handleViewCoverLetter = (job) => {
    setModalCoverLetterVisible(true);
    setCoverLetter(job?.application?.cover_letter || 'Cover letter not available');
  };

  const fetchAppliedJobs = async (userId, filter, page = 1) => {
    try {

      const response = await axios.post(
        `${network.serverip}/api/applied-joblist-forweb`,
        {
          userId: userId,
          sortByDate: sortByDate,
          job_category_id: filter,
          page: page
        },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      if (response.data.success && isMountedRef.current) {
        const jobData = response.data.data || [];
        const categoriesData = response.data.allCategoriesApplied || [];
        setJobs(jobData);
        setAllCategories(categoriesData);
        setPaginationData(response.data.pagination);
        setTotalJobCount(response.data.totalJobCount);
        console.log('Fetched jobs:', jobData);
      } else if (!response.data.success) {
        console.error('Failed to fetch jobs');
      }

    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };

  useEffect(() => {
    if (user && user.id) {
      setUserId(user.id)
    }
  }, [user]);

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {

    if (userId) {
      const fetchData = async () => {
        await fetchAppliedJobs(userId);
      };

      fetchData();
    }

  }, [userId, sortByDate]);

  const styles = dashboardAppliedJobsStyling();

  return (
    <>
      <PageWrapper
        headerConfig={{
          button: "profile",
          isFluid: true,
          bgClass: "bg-default",
          reveal: false,
        }}
      >
        <div className="dashboard-main-container mt-33 mt-md-30">
          <div className="container">
            <div className="mb-18">
              <div className="row mb-11 align-items-center">
                <div className="col-lg-6 mb-lg-0 mb-4">
                  <h3 className="font-size-6 mb-0">{`Applied Jobs (${totalJobCount})`}</h3>
                </div>
                <div className="col-lg-6">
                  <div className="d-flex flex-wrap align-items-center justify-content-lg-end">
                    <p className="font-size-4 mb-0 mr-6 py-2">Filter by Job Category:</p>
                    <div className="h-px-48">
                      {/* <Select
                        options={defaultJobs}
                        className="pl-0 h-100 arrow-3 arrow-3-black min-width-px-273  text-black-2 d-flex align-items-center w-100"
                        border={false}
                      /> */}
                      <Select
                        options={jobFilterOptions}
                        className="pl-0 h-100 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center w-100"
                        border={false}
                        onChange={(selectedOption) => {
                          handleFilterJob(selectedOption.value);
                          setFilter(selectedOption.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white shadow-8 pt-7 rounded pb-9 px-11">
                <div className="table-responsive ">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="pl-5 border-0 font-size-4 font-weight-bold"
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          className="pl-5 border-0 font-size-4 font-weight-bold"
                        >
                          Image
                        </th>
                        <th
                          scope="col"
                          className="pl-5 border-0 font-size-4 font-weight-bold"
                        >
                          Title
                        </th>
                        <th
                          scope="col"
                          className="pl-5 border-0 font-size-4 font-weight-bold"
                        >
                          Job Type
                        </th>
                        <th
                          scope="col"
                          className="pl-5 border-0 font-size-4 font-weight-bold"
                        >
                          Job category
                        </th>
                        {/* <th
                          scope="col"
                          className="pl-5 border-0 font-size-4 font-weight-bold"
                        >
                          Location
                        </th> */}
                        <th
                          scope="col"
                          className="pl-5 border-0 font-size-4 font-weight-bold"
                        >
                          <button
                            onClick={() => {
                              handleSortByDate();
                            }}
                            className="d-flex align-items-center justify-content-center bg-transparent border-0 p-0"
                            style={styles.tableHeadingButton}
                          >
                            <span>
                              Applied on
                              <span className="ml-1">
                                {sortByDate === "ascending" ? "▲" : "▼"}
                              </span>
                            </span>
                          </button>
                        </th>
                        <th
                          scope="col"
                          className="pl-5 border-0 font-size-4 font-weight-bold"
                        >
                          Cover Letter
                        </th>
                        <th
                          scope="col"
                          className="pl-5 border-0 font-size-4 font-weight-bold"
                        >Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {jobs.length === 0 ? (
                        <tr>
                          <td colSpan="100%" className="text-center py-4">
                            <p className="mb-0">No jobs found</p>
                          </td>

                        </tr>
                      ) : (<>
                        {
                          jobs.map((job, index) => (
                            <tr className="border border-color-2" key={job.id}>
                              <th scope="row"
                                className="table-y-middle py-7 min-width-px-90"
                              >
                                <Link
                                  to={`/job-details/?id=${job.id}`}
                                  className="font-size-3 mb-0 font-weight-semibold text-black-2"
                                >
                                  <span>
                                    {`${((paginationData.current_page - 1) * 10) + index + 1}`}
                                  </span>
                                </Link>
                              </th>
                              <td
                                className="table-y-middle py-7 min-width-px-70"
                              >
                                <div className="d-flex justify-content-start align-items-center">
                                  <button
                                    onClick={() => gContext.toggleImageViewModal(job?.image)}
                                    style={styles.imageViewButton}
                                  >
                                    <img
                                      src={job && job.image ? imageBaseUrl + job.image : ""}
                                      alt=""
                                      style={styles.imageContainer}
                                    />
                                  </button>
                                </div>
                              </td>
                              <td
                                className="table-y-middle py-7 min-width-px-125"
                              >
                                <Link
                                  to={`/job-details/?id=${job.id}`}
                                  className="font-size-3 mb-0 font-weight-semibold text-black-2"
                                >
                                  <span>
                                    {`${job.title}`}
                                  </span>
                                </Link>
                              </td>
                              <td className="table-y-middle py-7 min-width-px-110">
                                <h3 className="font-size-3 font-weight-normal text-black-2 mb-0">
                                  {job.job_type?.title || ''}
                                </h3>
                              </td>
                              <td className="table-y-middle py-7 min-width-px-135">
                                <h3 className="font-size-3 font-weight-normal text-black-2 mb-0">
                                  {job.job_category?.title || ''}
                                </h3>
                              </td>
                              {/* <td className="table-y-middle py-7 min-width-px-125">
                                <h3 className="font-size-3 font-weight-normal text-black-2 mb-0">
                                  {job.location?.title || ''}
                                </h3>
                              </td> */}
                              <td className="table-y-middle py-7 min-width-px-135">
                                <h3 className="font-size-3 font-weight-normal text-black-2 mb-0">
                                  {job?.application?.created_at ?
                                    new Date(job.application.created_at).toLocaleDateString('default', {
                                      day: '2-digit',
                                      month: 'short',
                                      year: 'numeric'
                                    }).replace(/ /g, '-')
                                    : ''}
                                </h3>
                              </td>
                              <td className="table-y-middle py-7 min-width-px-125">
                                <button
                                  onClick={() => handleViewCoverLetter(job)}
                                  className="btn btn-outline-success rounded-3"
                                  style={styles.applicantsButtonStyle}
                                >
                                  {`View`}
                                </button>
                              </td>
                              <td className="table-y-middle py-7 pr-0">
                                <h3 className="font-size-3 font-weight-normal text-black-2 mb-0">
                                  {job.application?.application_status || 'Not available'}
                                </h3>
                              </td>
                            </tr>
                          ))
                        }
                      </>
                      )}

                    </tbody>
                  </table>
                </div>
                {/* Pagination Controls */}
                <div className="pagination w-100 d-flex justify-content-center align-items-center mt-5">
                  {paginationData && paginationData.current_page > 1 ? (
                    <button
                      onClick={() => fetchAppliedJobs(userId, filter, paginationData.current_page - 1)}
                      className="btn btn-outline-success rounded-3"
                      style={styles.previousButtonStyle}
                    >
                      <span className="btn-inner--icon"><i className="fas fa-chevron-left"></i></span>
                      <span className="pl-3">Previous</span>
                    </button>
                  ) : (
                    <button
                      className="btn btn-outline-gray rounded-3"
                      style={styles.previousButtonStyle}
                      disabled
                    >
                      <span className="btn-inner--icon"><i className="fas fa-chevron-left"></i></span>
                      <span className="pl-3">Previous</span>
                    </button>
                  )}

                  {paginationData && (
                    <span className="ml-5 mr-5 text-success">
                      Page {paginationData.current_page} of {paginationData.last_page}
                    </span>
                  )}

                  {paginationData && paginationData.current_page < paginationData.last_page ? (
                    <button
                      onClick={() => fetchAppliedJobs(userId, filter, paginationData.current_page + 1)}
                      className="btn btn-outline-success rounded-3"
                      style={styles.nextButtonStyle}
                    >
                      <span className="pr-3">Next</span>
                      <span className="btn-inner--icon"><i className="fas fa-chevron-right"></i></span>
                    </button>
                  ) : (
                    <button
                      className="btn btn-outline-gray rounded-3"
                      style={styles.nextButtonStyle}
                      disabled
                    >
                      <span className="pr-3">Next</span>
                      <span className="btn-inner--icon"><i className="fas fa-chevron-right"></i></span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
      <Modal
        size="lg"
        centered
        show={modalCoverLetterVisible}
      >
        <Modal.Body className="p-0">
          <button
            type="button"
            className="circle-32 btn-reset bg-white pos-abs-tr mt-n6 mr-lg-n6 focus-reset shadow-10"
            onClick={handleModalClose}
          >
            <i className="fas fa-times"></i>
          </button>
          <div className="bg-white rounded-4 overflow-auto">
            <div className="pr-xl-0 pr-xxl-14 p-5 px-xs-12 pt-7 pb-5">
              <h4 className="font-size-6 font-weight-semibold mb-7 mt-5 text-black-2">
                Cover Letter
              </h4>
              <p className="font-size-4 mb-8 text-justify">
                {coverLetter}
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DashboardAppliedJobs;

function dashboardAppliedJobsStyling() {
  const styles = {

    imageContainer: {
      width: '3rem',
      height: '3rem',
      objectFit: 'cover',
      borderRadius: '5px',
    },

    applicantsButtonStyle: {
      padding: '0.2rem 0.5rem',
      minWidth: '5rem',
      display: 'inline-flex',
      alignItems: 'center',
    },

    viewButtonStyle: {
      padding: '0.2rem 0.5rem',
      minWidth: '5rem',
      display: 'inline-flex',
      alignItems: 'center',
    },

    editButtonStyle: {
      padding: '0.2rem 0.5rem',
      minWidth: '5rem',
      display: 'inline-flex',
      alignItems: 'center',
    },

    deleteButtonStyle: {
      padding: '0.2rem 0.5rem',
      minWidth: '5rem',
      display: 'inline-flex',
      alignItems: 'center',
    },

    previousButtonStyle: {
      padding: '0.2rem 0.5rem',
      minWidth: '7rem',
      display: 'inline-flex',
      alignItems: 'center',
    },

    nextButtonStyle: {
      padding: '0.2rem 0.5rem',
      minWidth: '7rem',
      display: 'inline-flex',
      alignItems: 'center',
    },

    imageViewButton: {
      outline: 'none',
      border: 'none',
      background: 'none',
      padding: '0',
    },

    tableHeadingButton: {
      cursor: "pointer",
      fontSize: "inherit",
      fontWeight: "inherit",
      color: "inherit",
      textAlign: "left",
      background: "none",
      outline: 'none',
    }

  };

  return styles;
}

